<template>
  <div>
    <div class="uk-text-large">Constantes</div>
    <div class="uk-margin-top" v-if="!isLoading">
      <ul class="uk-child-width-expand" uk-tab>
        <li v-for="(group, index) in constantGroups" :key="'group-' + index">
          <a href="#">{{ group.name ? group.name : "Sin grupo" }}</a>
        </li>
      </ul>

      <ul class="uk-switcher uk-margin">
        <li v-for="(group, index) in constantGroups" :key="'table-' + index">
          <table
            class="uk-table uk-table-striped uk-table-middle uk-table-hover uk-table-divider"
          >
            <thead>
              <tr>
                <th width="30%">Constante</th>
                <th width="50%">Descripción</th>
                <th width="10%">Valor</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="constant in group.constants" :key="constant.id">
                <td>{{ constant.name }}</td>
                <td>{{ constant.description }}</td>
                <td>{{ constant.value }}</td>
                <td>
                  <div class="uk-text-right">
                    <div
                      class="uk-button-group uk-border-rounded uk-overflow-hidden"
                    >
                      <button
                        class="uk-button uk-button-default"
                        uk-icon="pencil"
                        href="#edit-constant-modal"
                        uk-toggle
                        @click="setEditMode(constant)"
                      ></button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </li>
      </ul>
    </div>

    <div v-else class="uk-text-center">
      <div uk-spinner></div>
    </div>

    <div
      id="edit-constant-modal"
      class="uk-flex-top"
      uk-modal="bg-close: false"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large" v-if="selectedConstant">
          Editar constante {{ selectedConstant.name }}
        </div>

        <ValidationObserver v-slot="{ invalid }">
          <ValidationProvider name="Valor" rules="required" v-slot="{ errors }">
            <div class="uk-margin-top">Valor</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Valor"
              v-model="model.value"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <div class="uk-text-center uk-margin-top">
            <button
              class="uk-button uk-button-primary uk-border-rounded"
              :disabled="invalid || isLoading"
              @click="update"
            >
              <span v-if="isLoading" uk-spinner></span>
              <span v-else> Editar constante </span>
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";

const blankModel = {
  value: null,
};

export default {
  name: "ConstantsIndex",

  data() {
    return {
      constants: null,
      constantGroups: null,
      isLoading: false,
      selectedConstant: null,
      model: this._.cloneDeep(blankModel),
    };
  },

  mounted() {
    this.fetchConstants();
  },

  methods: {
    resetModel() {
      this.model = this._.cloneDeep(blankModel);
    },
    fetchConstants() {
      this.isLoading = true;
      this.axios
        .get(window.location.pathname + "?dataOnly=true")
        .then(({ data: { constants, constantGroups } }) => {
          this.constantGroups = constantGroups;
          this.constants = constants;
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setEditMode(constant) {
      this.selectedConstant = constant;
      this.model.value = constant.value;
    },
    update() {
      this.isLoading = true;
      this.axios
        .put("/constants/" + this.selectedConstant.id.toString(), this.model)
        .then(() => {
          UIkit.notification({
            message:
              "Constante - " + this.selectedConstant.name + " - actualizada",
            status: "success",
            pos: "bottom-left",
          });
          this.fetchConstants();
          UIkit.modal("#edit-constant-modal").hide();
          this.resetModel();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.uk-tab > .uk-active > a {
  border-bottom: solid 2px;
}
</style>
